import Text from "@bo/components/common/Text";
import { InputFormControl } from "@bo/components/molecule/InputFormControl";
import { View, StyleSheet } from "react-native";
import * as React from "react";
import { t } from "i18next";
import { textLight, White } from "@bo/constants/colors";
import { usePutUserMeRequest } from "../../services/accountService";
import { useForm } from "react-hook-form";
import Toast from "react-native-toast-message";
import Button from "@bo/components/atom/Button";
import { FieldErrors } from "react-hook-form";
import { useIsMobile } from "../../hooks/mobileHook";

const desktopStyles = StyleSheet.create({
  title: {
    color: textLight,
  },
  titleContainer: {
    paddingHorizontal: 64,
    paddingVertical: 32,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingHorizontal: 64,
    paddingTop: 32,
    gap: 16,
  },
  holder: {
    marginTop: 64,
  },
  whiteSpace: {
    width: "100%",
    height: 32,
    backgroundColor: White,
  },
});

const mobileStyles = StyleSheet.create({
  title: {
    color: textLight,
    fontSize: 16,
  },
  titleContainer: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
    gap: 8,
  },
  holder: {
    marginTop: 32,
  },
  whiteSpace: {
    width: "100%",
    height: 16,
    backgroundColor: White,
  },
});

export default function ChangePassword() {
  const isMobile = useIsMobile();
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
    },
  });

  const areAllRequiredFieldsAreFilled = (errors: FieldErrors) => {
    return Object.values(errors).every((error) => error?.type !== "required");
  };

  const scrollToTop = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { mutate: updatePassword } = usePutUserMeRequest(
    (error) => {
      form.clearErrors(["currentPassword", "newPassword", "newPasswordRepeat"]);

      if (error.response?.status === 422) {
        const data = error.response.data as { message: string[] };

        if (data.message.some((message) => message.includes("password"))) {
          form.setError("newPassword", {
            message: t("screens.dashboard.account.error-password-not-strong-enough"),
          });
        }
      } else if (error.response?.status === 403) {
        form.setError("currentPassword", {
          message: t("screens.dashboard.account.error-wrong-password"),
        });
      }
    },
    () => {
      form.clearErrors();
      form.reset();
      form.trigger();
      scrollToTop();
      Toast.show({
        type: "success",
        text1: t("toasts.default-success-title"),
        text2: t("toasts.default-success-message", {
          description: t("screens.dashboard.account.toast-success-updating-password"),
        }),
      });
    },
  );

  const onPasswordFormSubmit = (data: { currentPassword: string; newPassword: string; newPasswordRepeat: string }) => {
    form.clearErrors(["currentPassword", "newPassword", "newPasswordRepeat"]);

    if (data.newPassword !== data.newPasswordRepeat) {
      form.setError("newPasswordRepeat", {
        message: t("screens.dashboard.account.error-passwords-not-matching"),
      });
      return;
    }

    updatePassword({
      password: data.newPassword,
      currentPassword: data.currentPassword,
    });
  };

  const styles = isMobile ? mobileStyles : desktopStyles;

  return (
    <View style={styles.holder}>
      <View style={styles.whiteSpace}></View>
      <View style={styles.titleContainer}>
        <Text type="H3"> {t("screens.dashboard.account.change-password-title")}</Text>
      </View>
      <View style={styles.contentContainer}>
        <Text type="P2.B" style={styles.title}>
          {t("screens.dashboard.account.field-current-password")}
        </Text>
        <InputFormControl
          inputProps={{
            placeholder: t("screens.dashboard.account.field-current-password-placeholder"),
            isPassword: true,
            value: form.watch("currentPassword"),
            errorText: form.formState.errors.currentPassword?.message,
          }}
          controllerProps={{
            control: form.control,
            name: "currentPassword",
          }}
        />

        <Text type="P2.B" style={styles.title}>
          {t("screens.dashboard.account.field-new-password")}
        </Text>
        <InputFormControl
          inputProps={{
            placeholder: t("screens.dashboard.account.field-new-password-placeholder"),
            isPassword: true,
            value: form.watch("newPassword"),
            errorText: form.formState.errors.newPassword?.message,
          }}
          controllerProps={{
            control: form.control,
            name: "newPassword",
          }}
        />
        <Text type="P2.B" style={styles.title}>
          {t("screens.dashboard.account.field-repeat-new-password")}
        </Text>
        <InputFormControl
          inputProps={{
            placeholder: t("screens.dashboard.account.field-repeat-new-password-placeholder"),
            isPassword: true,
            value: form.watch("newPasswordRepeat"),
            errorText: form.formState.errors.newPasswordRepeat?.message,
          }}
          controllerProps={{
            control: form.control,
            name: "newPasswordRepeat",
          }}
        />
        <Button
          type="primary"
          onPress={form.handleSubmit(onPasswordFormSubmit)}
          isDisabled={!areAllRequiredFieldsAreFilled(form.formState.errors)}
        >
          Shrani
        </Button>
      </View>
    </View>
  );
}
