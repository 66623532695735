import { background } from "@bo/constants/colors";
import * as React from "react";
import { ScrollView, StyleSheet, View, ViewStyle } from "react-native";
import { useIsMobile } from "../../../../hooks/mobileHook";

const desktopStyles = StyleSheet.create({
  outerContainer: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 64,
    paddingHorizontal: 32,
  },
  innerContainer: {
    backgroundColor: background,
    paddingVertical: 32,
    borderRadius: 8,
    width: 650,
  },
});

const mobileStyles = StyleSheet.create({
  outerContainer: {
    flexGrow: 1,
    paddingHorizontal: 8,
    paddingVertical: 8,
    width: "100%",
  },
  innerContainer: {
    backgroundColor: background,
    paddingVertical: 16,
    width: "100%",
    minHeight: "100%",
  },
});

interface Props {
  children: React.ReactNode;
  contentContainerStyle?: ViewStyle;
}

export default function DashboardContentContainer({ children, contentContainerStyle }: Props) {
  const isMobile = useIsMobile();

  const styles = isMobile ? mobileStyles : desktopStyles;

  return (
    <View style={styles.outerContainer}>
      <ScrollView contentContainerStyle={[styles.innerContainer, contentContainerStyle]}>{children}</ScrollView>
    </View>
  );
}
