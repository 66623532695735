import { useTranslation } from "react-i18next";

export function formatCurrency(value: number, isCents: boolean = true) {
  const { i18n } = useTranslation();
  const locale = i18n.language === "sl" ? "sl-SI" : "en-US";

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
  }).format(value / (isCents ? 100 : 1));
}
