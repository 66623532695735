import * as React from "react";
import { t } from "i18next";

import { usePostAddParticipantRequest } from "./service";
import Button from "@bo/components/atom/Button";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import Toast from "react-native-toast-message";

interface Props {
  userId: string;
  image?: string;
  name: string | null;
  buttonText: string;
  role: "THERAPIST" | "USER";
  isDisabled: boolean;
  meetingId: string | null;
}

export default function JoinVideoCallButton({ name, role, image, buttonText, userId, isDisabled, meetingId }: Props) {
  const navigation = useNavigation<NavigationProp<any>>();
  const [isLoading, setIsLoading] = React.useState(false);

  const { mutate: addParticipant } = usePostAddParticipantRequest(
    () => {
      console.log("Error adding participant");
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "povezovanju na klic" }),
      });
      setIsLoading(false);
    },
    (data) => {
      setIsLoading(false);
      if (data) {
        console.log("Participant added successfully", data);
        console.log("token:", data.data.data.token);
        navigation.navigate("Call", { token: data.data.data.token });
      } else {
        console.log("Error: No data returned");
        Toast.show({
          type: "error",
          text1: t("toasts.default-error-title"),
          text2: t("toasts.default-error-message", { description: "povezovanju na klic" }),
        });
      }
    },
  );

  const handleAddParticipant = () => {
    console.log("meetingId:", meetingId);
    if (meetingId) {
      console.log("Adding participant to meeting", meetingId);
      addParticipant({
        meetingId,
        name: name ?? "Someone",
        picture: "https://assets-bo-public.s3.eu-central-1.amazonaws.com/icon-image.png",
        role,
        customParticipantId: userId,
      });
    } else {
      console.log("No callMeetingId found");
    }
  };

  const onJoinCallPressed = () => {
    if (isLoading || isDisabled) {
      console.log("Button is disabled or loading");
      return;
    }
    console.log("Joining call");
    setIsLoading(true);
    handleAddParticipant();
  };

  return (
    <Button type="primary" onPress={onJoinCallPressed} isLoading={isLoading} isDisabled={isDisabled || isLoading}>
      {buttonText}
    </Button>
  );
}
