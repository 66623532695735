export const formatNumberWithCommas = (value: number): string => {
  return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
};

export const parseNumberString = (value: string): number => {
  const parsed = parseFloat(value.replace(/,/g, ""));
  return isNaN(parsed) ? 0 : parsed;
};

/**
 * Formats a number representing cents into a string with euros and cents.
 * E.g., 5544 => "55,44 €"
 *
 * @param value - The amount in cents.
 * @returns The formatted string.
 */
export const formatCentsToEuros = (value: number): string => {
  if (typeof value !== "number" || isNaN(value)) {
    return "0,00 €";
  }
  const euros = Math.floor(value / 100);
  const cents = value % 100;
  return `${euros},${cents.toString().padStart(2, "0")} €`;
};

/**
 * Parses a formatted euro string back to cents.
 * E.g., "55,44 €" => 5544
 *
 * @param value - The formatted string.
 * @returns The amount in cents.
 */
export const parseEurosToCents = (value: string): number => {
  const numericString = value.replace(/[^\d,]/g, ""); // Remove non-numeric characters except comma
  const [euros, cents] = numericString.split(",");

  // Handle cases where cents are undefined
  const eurosNumber = parseInt(euros, 10) || 0;
  const centsNumber = parseInt(cents, 10) || 0;

  // Ensure cents do not exceed 99
  const adjustedCents = Math.min(centsNumber, 99);

  return eurosNumber * 100 + adjustedCents;
};
