import * as React from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import { TabScreen, Tabs, TabsProvider } from "react-native-paper-tabs";
import { textLight } from "@bo/constants/colors";

import DashboardTemplate from "./template/Template";
import Text from "@bo/components/common/Text";
import { NavigationParamList } from "../../navigation/RootStack";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useGetAppointments } from "../../services/appointmentsService";
import { ActivityCard } from "@bo/components/molecule/ActivityCard";
import { AppointmentResponse } from "@bo/types/appointments";
import { useIsMobile } from "../../hooks/mobileHook";
import Toast from "react-native-toast-message";

const desktopStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 64,
    paddingBottom: 32,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  tabScreen: {
    borderColor: textLight,
    borderTopWidth: 1,
    paddingTop: 32,
  },
});

const mobileStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 32,
    paddingBottom: 16,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  tabScreen: {
    borderColor: textLight,
    borderTopWidth: 1,
    paddingTop: 16,
  },
});

export default function AppointmentsScreen() {
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const { i18n } = useTranslation();
  const locale = i18n.language === "sl" ? "sl-SI" : "en-US";
  const isMobile = useIsMobile();

  const styles = isMobile ? mobileStyles : desktopStyles;

  const [upcoming, setUpcoming] = React.useState<AppointmentResponse[]>([]);
  const [past, setPast] = React.useState<AppointmentResponse[]>([]);

  const { refetch: fetchAppointments, data: appointments, error: appointmentsError } = useGetAppointments();

  const formatDateTime = (date: Date) => {
    const str = new Date(date).toLocaleString(locale, {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  React.useEffect(() => {
    fetchAppointments();
  }, []);

  React.useEffect(() => {
    if (appointmentsError) {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "prodobivanju terminov srečanj" }),
      });
    }
  }, [appointmentsError]);

  React.useEffect(() => {
    if (appointments) {
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);

      setUpcoming(
        appointments.data
          .filter((appointment) => new Date(appointment.from) > todayStart && appointment.status === "PENDING")
          .sort((a, b) => new Date(a.from).getTime() - new Date(b.from).getTime()),
      );
      setPast(
        appointments.data
          .filter((appointment) => new Date(appointment.from) < todayStart || appointment.status !== "PENDING")
          .sort((a, b) => new Date(b.from).getTime() - new Date(a.from).getTime()),
      );
    }
  }, [appointments]);

  return (
    <DashboardTemplate>
      <View style={styles.titleContainer}>
        <Text type="H3">{t("screens.dashboard.appointments.title")}</Text>
      </View>
      <TabsProvider defaultIndex={0}>
        <Tabs>
          <TabScreen label={t("screens.dashboard.appointments.upcoming")}>
            <View style={styles.tabScreen}>
              {upcoming.map((appointment) => (
                <ActivityCard
                  isSeparatorVisible={true}
                  key={appointment.id}
                  title={`${t("screens.dashboard.appointment.title")} ${appointment.customer?.name}`}
                  description={`${formatDateTime(appointment.from)} - ${t(`therapy-status.status-${appointment.status.toLowerCase()}`)}`}
                  icon={<></>}
                  isCheckboxHidden={true}
                  onPress={() => navigation.navigate("Appointment", { id: appointment.id })}
                />
              ))}
            </View>
          </TabScreen>
          <TabScreen label={t("screens.dashboard.appointments.past")}>
            <View style={styles.tabScreen}>
              {past.map((appointment) => (
                <ActivityCard
                  isSeparatorVisible={true}
                  key={appointment.id}
                  title={`${t("screens.dashboard.appointment.title")} ${appointment.customer?.name}`}
                  description={`${formatDateTime(appointment.from)} - ${t(`therapy-status.status-${appointment.status.toLowerCase()}`)}`}
                  icon={<></>}
                  isCheckboxHidden={true}
                  onPress={() => navigation.navigate("Appointment", { id: appointment.id })}
                />
              ))}
            </View>
          </TabScreen>
        </Tabs>
      </TabsProvider>
    </DashboardTemplate>
  );
}
