import * as React from "react";
import { t } from "i18next";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { primaryLight } from "@bo/constants/colors";

import Button from "@bo/components/atom/Button";
import { useAuthContext } from "../../../../hooks/authHook";
import { useIsMobile } from "../../../../hooks/mobileHook";
import { useNavbarContext } from "../../../../hooks/navbarHook";

const desktopStyles = StyleSheet.create({
  header: {
    backgroundColor: primaryLight,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 32,
  },
  fragment: {
    flexDirection: "row",
    alignItems: "center",
    gap: 64,
  },
  logo: {
    width: 100,
    height: 57,
  },
  menu: {
    display: "none",
  },
});

const mobileStyles = StyleSheet.create({
  header: {
    backgroundColor: primaryLight,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 32,
  },
  fragment: {
    flexDirection: "row",
    alignItems: "center",
    gap: 32,
  },
  logo: {
    width: 100,
    height: 57,
  },
  menu: {
    width: 24,
    height: 24,
  },
});

export default function DashboardHeader() {
  const { logout } = useAuthContext();
  const { isActive, setActive } = useNavbarContext();
  const isMobile = useIsMobile();

  const styles = isMobile ? mobileStyles : desktopStyles;

  return (
    <View style={styles.header}>
      <View style={styles.fragment}>
        <Pressable onPress={() => setActive(!isActive)}>
          <Image source={require("../../../../assets/menu.png")} style={styles.menu} />
        </Pressable>
        <Image source={require("../../../../assets/logo.png")} style={styles.logo} />
      </View>
      <Button type="secondary" onPress={logout}>
        {t("template.header.button-log-out")}
      </Button>
    </View>
  );
}
