import * as React from "react";
import { t } from "i18next";
import { Image, ImageSourcePropType, Pressable, View, StyleSheet } from "react-native";
import { grayTitle, textLight } from "@bo/constants/colors";
import DashboardTemplate from "./template/Template";
import Text from "@bo/components/common/Text";
import Button from "@bo/components/atom/Button";
import { InputFormControl } from "@bo/components/molecule/InputFormControl";
import { GroupedButtons } from "@bo/components/atom/GroupedButtons";
import GridOptions from "@bo/components/molecule/GridOptions";
import { blobToBase64 } from "@bo/utils";
import { useForm, Controller } from "react-hook-form";
import { usePostTherapistProfileMeRequest, usePutUserMeRequest } from "../../services/accountService";
import { useAuthContext } from "../../hooks/authHook";
import { ImagePickerAsset, MediaTypeOptions, launchImageLibraryAsync } from "expo-image-picker";
import { usePostAssetRequest } from "../../services/assetsService";
import { useProfileImage } from "@bo/hooks";
import { useIsMobile } from "../../hooks/mobileHook";
import Toast from "react-native-toast-message";
import { GridOption } from "@bo/components/molecule/GridOptions/interface";
import { GridOption as ThemeGridOption } from "./GridOptions";
import AccountThemes from "./AccountThemes";
import { ContentTheme } from "@bo/types";
import ChangePassword from "./ChangePassword";
import { formatCentsToEuros, parseEurosToCents } from "../../utils/numberFormatter";

const desktopStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 64,
    paddingBottom: 32,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingHorizontal: 64,
    paddingTop: 32,
    gap: 16,
  },
  multiFromControls: {
    flexDirection: "row",
    gap: 32,
  },
  multiFormControlsChild: {
    flexGrow: 1,
    flexShrink: 1,
  },
  multiline: {
    height: 120,
    borderRadius: 16,
  },
  controlWithTitleContainer: {
    gap: 16,
  },
  imageContainer: {
    aspectRatio: 16 / 9,
    borderWidth: 1,
    borderColor: textLight,
    borderRadius: 16,
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 16,
    objectFit: "cover",
    // @ts-expect-error Web style only
    objectPosition: "center",
  },
  imagePlacholderContainer: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: 32,
  },
  button: {
    marginTop: 64,
  },
  formControlTitle: {
    color: grayTitle,
    marginTop: 16,
  },
  formControlDescription: {
    color: grayTitle,
    marginBottom: 16,
  },
  formControlCount: {
    color: grayTitle,
    textAlign: "right",
  },
});

const mobileStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 16,
    paddingBottom: 12,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingHorizontal: 16,
    paddingTop: 12,
    gap: 12,
  },
  multiFromControls: {
    gap: 12,
  },
  multiFormControlsChild: {
    flexGrow: 1,
    flexShrink: 1,
  },
  multiline: {
    height: 100,
    borderRadius: 12,
  },
  controlWithTitleContainer: {
    gap: 8,
  },
  imageContainer: {
    aspectRatio: 1,
    borderWidth: 1,
    borderColor: textLight,
    borderRadius: 12,
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 12,
    objectFit: "cover",
    // @ts-expect-error Web style only
    objectPosition: "center",
  },
  imagePlacholderContainer: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
  },
  button: {
    marginTop: 24,
  },
  formControlTitle: {
    color: grayTitle,
    marginTop: 12,
  },
  formControlDescription: {
    color: grayTitle,
    marginBottom: 12,
  },
  formControlCount: {
    color: grayTitle,
    textAlign: "right",
  },
});

interface FormValues {
  name: string;
  surname: string;
  title: string;
  subtitle: string;
  about: string;
  approach: string;
  appointmentDuration: number;
  appointmentPrice: number;
  gender: string;
  therapyType: string[];
  email: string;
  profileImage: ImageSourcePropType | null;
  contentTheme: ContentTheme[];
  phoneNumber: string;
  address: string;
  bio: string;
}

export default function AccountScreen() {
  const form = useForm<FormValues>({
    defaultValues: {
      email: "",
      name: "",
      surname: "",
      title: "",
      profileImage: null,
      contentTheme: [],
      appointmentPrice: undefined,
      bio: "",
      phoneNumber: "",
      address: "",
      subtitle: "",
      about: "",
      approach: "",
      appointmentDuration: 0,
      gender: "",
      therapyType: [],
    },
  });

  const currentProfileImage = useProfileImage();
  const isMobile = useIsMobile();
  const { user, therapistProfile, refetchMe, setTherapistProfile } = useAuthContext();
  const [pickedProfileImage, setPickedProfileImage] = React.useState<ImagePickerAsset>();
  const [imageSource, setImageSource] = React.useState<ImageSourcePropType>();
  const [isImageUploaded, setIsImageUploaded] = React.useState(false);
  const [titleOptions, setTitleOptions] = React.useState<GridOption[]>([
    {
      label: t("titles.option-psychologist"),
      option: "psychologist",
      isSelected: false,
    },
    {
      label: t("titles.option-psychotherapist"),
      option: "psychotherapist",
      isSelected: false,
    },
    {
      label: t("titles.option-psychotherapy-specialist"),
      option: "psychotherapy-specialist",
      isSelected: false,
    },
    {
      label: t("titles.option-intern"),
      option: "intern",
      isSelected: false,
    },
  ]);
  const [therapyTypeOptions, setTherapyTypeOptions] = React.useState<GridOption[]>([
    {
      label: t("therapy-types.option-individual"),
      option: "individual",
      isSelected: false,
    },
    {
      label: t("therapy-types.option-couple"),
      option: "couple",
      isSelected: false,
    },
    {
      label: t("therapy-types.option-family"),
      option: "family",
      isSelected: false,
    },
    {
      label: t("therapy-types.option-therapyforchildren"),
      option: "therapyforchildren",
      isSelected: false,
    },
  ]);
  const initialOptions: ThemeGridOption[] = Object.values(ContentTheme).map((challenge, idx) => ({
    id: idx,
    label: t(`content-themes.option-${challenge.toLowerCase()}`),
    option: challenge.toLowerCase(),
    isSelected: false,
    isProfileSelected: false,
  }));
  const [options, setOptions] = React.useState<ThemeGridOption[]>(initialOptions);
  const styles = isMobile ? mobileStyles : desktopStyles;

  const scrollToTop = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { mutate: updateUser } = usePutUserMeRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "shranjevanju podatkov" }),
      });
    },
    () => {
      scrollToTop();
      refetchMe();
    },
  );
  const { mutate: updateProfile } = usePostTherapistProfileMeRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "shranjevanju podatkov" }),
      });
    },
    (data) => {
      scrollToTop();
      Toast.show({
        type: "success",
        text1: t("toasts.default-success-title"),
        text2: t("toasts.default-success-message", { description: "shranil-a nove podatke" }),
      });
      setTherapistProfile(data.data);
    },
  );
  const { mutate: updateProfileImage } = usePostAssetRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "shranjevanju podatkov" }),
      });
    },
    (data) => {
      scrollToTop();
      setIsImageUploaded(true);
      updateUser({ profileImageAssetId: data.data.id });
    },
  );

  const selectProfileImage = async () => {
    const image = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!image.assets) {
      return;
    }

    setPickedProfileImage(image.assets[0]);
  };

  const genderOptions = [
    {
      label: t("genders.option-male"),
      value: "Male",
    },
    {
      label: t("genders.option-female"),
      value: "Female",
    },
  ];

  React.useEffect(() => {
    if (pickedProfileImage?.uri) {
      setImageSource({ uri: pickedProfileImage.uri });
      return;
    }

    if (currentProfileImage?.data) {
      blobToBase64(currentProfileImage.data)
        .then((base64) => {
          setImageSource({ uri: base64 });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [currentProfileImage, pickedProfileImage]);

  React.useEffect(() => {
    setIsImageUploaded(false);
  }, [pickedProfileImage]);

  React.useEffect(() => {
    if (user && therapistProfile) {
      form.setValue("name", user.name || "");
      form.setValue("surname", therapistProfile.surname);
      form.setValue("title", therapistProfile.title);
      form.setValue("subtitle", therapistProfile.subTitle);
      form.setValue("about", therapistProfile.aboutDescription);
      form.setValue("approach", therapistProfile.approachDescription);
      form.setValue("appointmentDuration", therapistProfile.appointmentDuration);
      form.setValue("appointmentPrice", therapistProfile.appointmentPrice);
      form.setValue("gender", therapistProfile.gender);
      form.setValue("therapyType", therapistProfile.therapyType);

      const initialOptionStrings = new Set(initialOptions.map((option) => option.option));
      const allProfileOptions = [...therapistProfile.therapyChallenges, ...therapistProfile.therapyTopChallenges];
      const customOptionStrings = allProfileOptions.filter((option) => !initialOptionStrings.has(option));
      const customOptions = customOptionStrings.map((option, idx) => ({
        id: initialOptions.length + idx,
        option,
        isSelected: false,
        isProfileSelected: false,
        label: option,
      }));
      const allOptions = [...initialOptions, ...customOptions];

      // Initialize option states
      const updatedOptions = allOptions.map((option) => {
        const isSelected = therapistProfile.therapyChallenges.includes(option.option);
        const isProfileSelected = therapistProfile.therapyTopChallenges.includes(option.option);
        return {
          ...option,
          isSelected,
          isProfileSelected,
        };
      });
      const titleIndex = titleOptions.findIndex((option) => option.option === therapistProfile.title);
      if (titleIndex !== -1) {
        const newOptions = [...titleOptions];
        newOptions.forEach((option) => (option.isSelected = false));
        newOptions[titleIndex].isSelected = true;
        setTitleOptions(newOptions);
      }
      const selectedTherapyTypes = therapistProfile.therapyType;
      const newOptions = therapyTypeOptions.map((option) => ({
        ...option,
        isSelected: selectedTherapyTypes.includes(option.option),
      }));
      setTherapyTypeOptions(newOptions);
      setOptions(updatedOptions);
    } else if (user) {
      form.setValue("name", user.name || " ");
    }
  }, [user, therapistProfile]);

  const onSubmit = () => {
    if (pickedProfileImage && !isImageUploaded) {
      const fileUrlArray = pickedProfileImage.uri.split(",");
      const binaryString = atob(fileUrlArray[fileUrlArray.length - 1]);
      const uIntArr = new Uint8Array(binaryString.length);

      let n = binaryString.length;

      while (n--) {
        uIntArr[n] = binaryString.charCodeAt(n);
      }

      const file = new File([uIntArr], pickedProfileImage.fileName ?? `profile-image-${new Date()}`);

      updateProfileImage({
        name: file.name,
        file: file,
      });
    }

    updateUser({
      name: form.getValues().name,
    });

    updateProfile({
      surname: form.getValues().surname,
      title: form.getValues().title,
      subTitle: form.getValues().subtitle,
      aboutDescription: form.getValues().about,
      approachDescription: form.getValues().approach,
      appointmentDuration: Number(form.getValues().appointmentDuration),
      appointmentPrice: Number(form.getValues().appointmentPrice),
      therapyType: form.getValues().therapyType,
      gender: form.getValues().gender,
      therapyChallenges: options.filter((option) => option.isSelected).map((option) => option.option),
      therapyTopChallenges: options.filter((option) => option.isProfileSelected).map((option) => option.option),
    });
  };

  const getValueIndex = (options: { label: string; value: string }[], value: string) => {
    const index = options.findIndex((option) => option.value === value);

    if (index === -1) {
      return undefined;
    }

    return index;
  };

  const handleTitleOptionToggle = (option: GridOption, index: number) => {
    const newOptions = [...titleOptions];
    newOptions.forEach((option) => (option.isSelected = false));
    newOptions[index] = option;
    setTitleOptions(newOptions);
  };

  const handleTherapyTypeOptionToggle = (index: number) => {
    const newOptions = [...therapyTypeOptions];
    newOptions[index].isSelected = !newOptions[index].isSelected;
    setTherapyTypeOptions(newOptions);
  };

  const [aboutCharCount, setAboutCharCount] = React.useState(form.watch("about").length);
  const [approachCharCount, setApproachCharCount] = React.useState(form.watch("approach").length);

  React.useEffect(() => {
    if (form.watch("about")) {
      setAboutCharCount(form.watch("about").length);
    }
    if (form.watch("approach")) {
      setApproachCharCount(form.watch("approach").length);
    }
  }, [form.watch("about"), form.watch("approach")]);

  return (
    <DashboardTemplate>
      <View style={styles.titleContainer}>
        <Text type="H3">{t("screens.dashboard.account.title")}</Text>
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.multiFromControls}>
          <View style={styles.multiFormControlsChild}>
            <InputFormControl
              inputProps={{
                placeholderAsLabel: true,
                placeholder: t("screens.dashboard.account.field-first-name"),
                value: form.watch("name"),
              }}
              controllerProps={{
                control: form.control,
                name: "name",
              }}
            />
          </View>
          <View style={styles.multiFormControlsChild}>
            <InputFormControl
              inputProps={{
                placeholderAsLabel: true,
                placeholder: t("screens.dashboard.account.field-last-name"),
                value: form.watch("surname"),
              }}
              controllerProps={{
                control: form.control,
                name: "surname",
              }}
            />
          </View>
        </View>
        <Controller
          control={form.control}
          name="gender"
          render={({ field: { onChange } }) => (
            <GroupedButtons
              options={genderOptions.map((option) => option.label)}
              isHorizontal={!isMobile}
              onChange={(_, index) => onChange(genderOptions[index].value)}
              defaultValueIndex={getValueIndex(genderOptions, form.watch("gender"))}
            />
          )}
        />
        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.image-description-title")}
        </Text>
        <View style={styles.controlWithTitleContainer}>
          <Pressable style={styles.imageContainer} onPress={selectProfileImage}>
            {imageSource ? (
              <Image source={imageSource} style={styles.image} />
            ) : (
              <View style={styles.imagePlacholderContainer}>
                <Text type="P1.B">{t("screens.dashboard.account.image-placeholder-first-row")}</Text>
                <Text type="P1">{t("screens.dashboard.account.image-placeholder-second-row")}</Text>
              </View>
            )}
          </Pressable>
        </View>
        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.field-title")}
        </Text>
        <Controller
          control={form.control}
          name="title"
          render={({ field: { onChange } }) => (
            <GridOptions
              options={titleOptions}
              onChange={(option, index) => {
                handleTitleOptionToggle(option, index);
                onChange(titleOptions[index].option);
              }}
            />
          )}
        />
        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.field-subtitle")}
        </Text>
        <Text type="P3.B" style={styles.formControlDescription}>
          {t("screens.dashboard.account.field-subtitle-description")}
        </Text>
        <InputFormControl
          inputProps={{
            placeholder: t("screens.dashboard.account.field-subtitle-placeholder"),
            value: form.watch("subtitle"),
          }}
          controllerProps={{
            control: form.control,
            name: "subtitle",
          }}
        />

        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.field-about")}
        </Text>
        <Text type="P3.B" style={styles.formControlDescription}>
          {t("screens.dashboard.account.field-about-description")}
        </Text>
        <InputFormControl
          inputProps={{
            placeholder: t("screens.dashboard.account.field-about-placeholder"),
            multiline: true,
            style: styles.multiline,
            outlineStyle: styles.multiline,
            value: form.watch("about"),
            maxLength: 300,
          }}
          controllerProps={{
            control: form.control,
            name: "about",
          }}
        />
        <Text type="P3.B" style={styles.formControlCount}>
          {`${aboutCharCount}/300`}
        </Text>

        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.field-approach")}
        </Text>
        <Text type="P3.B" style={styles.formControlDescription}>
          {t("screens.dashboard.account.field-approach-description")}
        </Text>
        <InputFormControl
          inputProps={{
            placeholder: t("screens.dashboard.account.field-approach-placeholder"),
            multiline: true,
            style: styles.multiline,
            outlineStyle: styles.multiline,
            value: form.watch("approach"),
            maxLength: 200,
          }}
          controllerProps={{
            control: form.control,
            name: "approach",
          }}
        />
        <Text type="P3.B" style={styles.formControlCount}>
          {`${approachCharCount}/200`}
        </Text>

        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.field-therapy-types")}
        </Text>
        <Controller
          control={form.control}
          name="therapyType"
          render={({ field: { onChange } }) => (
            <GridOptions
              options={therapyTypeOptions}
              onChange={(_, index) => {
                handleTherapyTypeOptionToggle(index);
                const selectedOptions = therapyTypeOptions.filter((opt) => opt.isSelected).map((opt) => opt.option);
                onChange(selectedOptions);
              }}
            />
          )}
        />

        <AccountThemes options={options} onOptionsChange={setOptions} />

        <Text type="P2.B" style={styles.formControlTitle}>
          {t("screens.dashboard.account.field-therapy-hours-title")}
        </Text>
        <View style={styles.multiFromControls}>
          <View style={styles.multiFormControlsChild}>
            <InputFormControl
              inputProps={{
                placeholderAsLabel: true,
                placeholder: `${t("screens.dashboard.account.field-appointment-duration")} (min)`,
                value: form.watch("appointmentDuration").toString(),
              }}
              controllerProps={{
                control: form.control,
                name: "appointmentDuration",
              }}
            />
          </View>
          <View style={styles.multiFormControlsChild}>
            <Controller
              control={form.control}
              name="appointmentPrice"
              rules={{
                required: "Appointment price is required",
                min: { value: 0, message: "Price cannot be negative" },
                // Add more validation rules as needed
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputFormControl
                  isPriceInput
                  inputProps={{
                    placeholderAsLabel: true,
                    placeholder: `${t("screens.dashboard.account.field-appointment-price")} (€)`,
                    value: formatCentsToEuros(value),
                    onChange: (rawValue: string) => {
                      console.log("Input Value Changed: ", rawValue);
                      // Allow only digits and comma
                      let sanitizedValue = rawValue.replace(/[^0-9,]/g, "");
                      // Ensure only one comma
                      const commaCount = (sanitizedValue.match(/,/g) || []).length;
                      if (commaCount > 1) {
                        sanitizedValue = sanitizedValue.replace(/,/g, "");
                      }
                      // Handle cases where comma is without cents
                      if (sanitizedValue.endsWith(",")) {
                        sanitizedValue += "00";
                      }
                      const parsedValue = parseEurosToCents(sanitizedValue);

                      // Prevent setting NaN
                      if (isNaN(parsedValue)) {
                        onChange(0);
                      } else {
                        onChange(parsedValue);
                      }
                    },
                    onBlur: onBlur,
                  }}
                  controllerProps={{
                    control: form.control,
                    name: "appointmentPrice",
                  }}
                />
              )}
            />
          </View>
        </View>

        <Button type="primary" buttonStyle={styles.button} onPress={onSubmit}>
          {t("screens.dashboard.account.button")}
        </Button>
      </View>
      <ChangePassword />
    </DashboardTemplate>
  );
}
