import * as React from "react";
import { t } from "i18next";
import { StyleSheet, View } from "react-native";

import DashboardNavBarItem from "./NavBarItem";
import { White, background } from "@bo/constants/colors";
import { useUnreadNotificationsContext } from "../../../../hooks/unreadNotificationsCountHook";
import { useIsMobile } from "../../../../hooks/mobileHook";
import { useNavbarContext } from "../../../../hooks/navbarHook";
import Text from "@bo/components/common/Text";
import { EMAILS } from "../../../../constants/emails";

const desktopStyles = StyleSheet.create({
  navbar: {
    backgroundColor: background,
    alignItems: "stretch",
    paddingVertical: 48,
    paddingHorizontal: 64,
    gap: 4,
    width: 300,
  },
  navbarInactive: {},
  info: {
    marginTop: 32,
  },
});

const mobileStyles = StyleSheet.create({
  navbar: {
    backgroundColor: White,
    alignItems: "stretch",
    paddingVertical: 16,
    paddingHorizontal: 16,
    gap: 4,
    width: 200,
    // @ts-expect-error Web style only
    position: "fixed",
    bottom: 0,
    left: 0,
    top: 89,
    zIndex: 100,
  },
  navbarInactive: {
    transform: [{ translateX: -200 }],
  },
  info: {
    marginTop: 16,
  },
});

export default function DashboardNavBar() {
  const { count } = useUnreadNotificationsContext();
  const { isActive } = useNavbarContext();
  const isMobile = useIsMobile();

  const styles = isMobile ? mobileStyles : desktopStyles;

  return (
    <View style={isActive ? styles.navbar : [styles.navbar, styles.navbarInactive]}>
      <DashboardNavBarItem route="Appointments" label={t("template.navigation.appointments")} />
      <DashboardNavBarItem
        route="Notifications"
        label={t("template.navigation.notifications")}
        numberBubbleCount={count}
      />
      <DashboardNavBarItem route="Schedule" label={t("template.navigation.schedule")} />
      <DashboardNavBarItem route="Account" label={t("template.navigation.account")} />
      <Text type="P3.B" textAlign="center" style={styles.info}>
        {t("template.support.title")} {EMAILS.SUPPORT}
      </Text>
    </View>
  );
}
