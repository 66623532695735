import * as React from "react";
import { View, StyleSheet, Alert } from "react-native";
import { t } from "i18next";

import Text from "@bo/components/common/Text";
import Button from "@bo/components/atom/Button";
import GridOptions, { GridOption } from "./GridOptions";
import Input from "@bo/components/atom/Input";
import { grayTitle } from "@bo/constants/colors";

const desktopStyles = StyleSheet.create({
  section: {
    flexDirection: "row",
    marginTop: 32,
    alignItems: "center",
  },
  input: {
    flex: 1,
    marginRight: 8,
  },
  addMoreInput: {
    width: "100%",
    flexDirection: "row",
    marginVertical: 16,
    alignItems: "center",
  },
  label: {
    flex: 1,
    color: grayTitle,
  },
  title: {
    color: grayTitle,
    marginBottom: 16,
    maxWidth: "75%",
  },
  description: {
    marginBottom: 16,
  },
});

interface AccountThemesProps {
  options: GridOption[];
  onOptionsChange: (options: GridOption[]) => void;
}

export default function AccountThemes({ options, onOptionsChange }: AccountThemesProps) {
  const [nextOptionId, setNextOptionId] = React.useState<number>(options.length);
  const [newOptionText, setNewOptionText] = React.useState("");
  const [isEditingTop3, setIsEditingTop3] = React.useState(false);
  const [isEditingOthers, setIsEditingOthers] = React.useState(false);
  const [isEditingThemes, setIsEditingThemes] = React.useState(false);

  const handleNewOptionChange = (text: string) => setNewOptionText(text);

  const handleAddNewOption = () => {
    const trimmedOptionText = newOptionText.trim();
    if (!trimmedOptionText) return;

    const newOption = {
      id: nextOptionId,
      option: trimmedOptionText,
      label: trimmedOptionText,
      isSelected: isEditingOthers,
      isProfileSelected: isEditingTop3,
    };

    onOptionsChange([...options, newOption]);
    setNextOptionId(nextOptionId + 1);
    setNewOptionText("");
  };

  const handleOptionToggle = (option: GridOption) => {
    const index = options.findIndex((o) => o.id === option.id);
    if (index !== -1) {
      if (isEditingTop3) {
        const currentIsProfileSelected = options[index].isProfileSelected;
        const newIsProfileSelected = !currentIsProfileSelected;

        const newOptions = options.map((o) =>
          o.id === option.id ? { ...o, isProfileSelected: newIsProfileSelected, isSelected: false } : o,
        );

        const selectedCount = newOptions.filter((o) => o.isProfileSelected).length;

        if (newIsProfileSelected && selectedCount > 3) {
          Alert.alert(t("screens.dashboard.account.themes-choose-top-3-alert"));
          return;
        }

        onOptionsChange(newOptions);
      } else if (isEditingOthers) {
        const currentIsSelected = options[index].isSelected;
        const newIsSelected = !currentIsSelected;

        const newOptions = options.map((o) =>
          o.id === option.id ? { ...o, isSelected: newIsSelected, isProfileSelected: false } : o,
        );
        onOptionsChange(newOptions);
      }
    }
  };

  // Handle editing state toggles
  const handleEdit = (toggle: boolean, isTop: boolean) => {
    if (isTop) {
      setIsEditingOthers(false);
      setIsEditingTop3(toggle);
    } else {
      setIsEditingTop3(false);
      setIsEditingOthers(toggle);
    }
  };

  interface SectionProps {
    label: string;
    isEditing: boolean;
    isAddNew?: boolean;
    onPress: () => void;
  }

  const Section = ({ label, isEditing, onPress, isAddNew }: SectionProps) => (
    <View style={desktopStyles.section}>
      <Text type="P2.B" style={desktopStyles.label}>
        {label}
      </Text>

      {isAddNew ? (
        <Button type={isEditing ? "primary" : "secondary"} onPress={onPress}>
          {t("screens.dashboard.account.button-add-more")}
        </Button>
      ) : isEditing ? (
        <Button type="primary" onPress={onPress}>
          {t("screens.dashboard.account.button-confirm")}
        </Button>
      ) : (
        <Button type="secondary" onPress={onPress}>
          {t("screens.dashboard.account.button-edit")}
        </Button>
      )}
    </View>
  );

  return (
    <View style={{ marginVertical: isEditingThemes ? 32 : 0 }}>
      {isEditingThemes && (
        <>
          <Text type="P1.I" style={desktopStyles.description}>
            {t("screens.dashboard.account.themes-title")}
          </Text>

          <GridOptions
            options={options.filter((opt) => !opt.isProfileSelected && !opt.isSelected)}
            onChange={handleOptionToggle}
            editable={isEditingTop3 || isEditingOthers}
          />
        </>
      )}

      <Section
        label={t("screens.dashboard.account.themes-choose-top-3-title")}
        isEditing={isEditingTop3}
        onPress={() => {
          handleEdit(!isEditingTop3, true);
          setIsEditingThemes(!isEditingThemes);
        }}
      />
      <Text type="P3.B" style={desktopStyles.title}>
        {t("screens.dashboard.account.themes-choose-top-3")}
      </Text>
      <GridOptions
        options={options.filter((opt) => opt.isProfileSelected)}
        onChange={handleOptionToggle}
        editable={isEditingTop3}
        canRemove={isEditingTop3}
        isTop
      />

      <Section
        label={t("screens.dashboard.account.themes-choose-others-title")}
        isEditing={isEditingOthers}
        onPress={() => {
          handleEdit(!isEditingOthers, false);
          setIsEditingThemes(!isEditingThemes);
        }}
      />
      <Text type="P3.B" style={desktopStyles.title}>
        {t("screens.dashboard.account.themes-choose-others")}
      </Text>
      <GridOptions
        options={options.filter((opt) => opt.isSelected)}
        onChange={handleOptionToggle}
        editable={isEditingOthers}
        canRemove={isEditingOthers}
      />
      {isEditingThemes && (
        <>
          <Section
            label={t("screens.dashboard.account.themes-add-new-title")}
            isEditing={!!newOptionText}
            onPress={handleAddNewOption}
            isAddNew
          />
          <Text type="P3.B" style={desktopStyles.title}>
            {t("screens.dashboard.account.themes-add-new-description")}
          </Text>
          <Input
            style={desktopStyles.input}
            placeholder={t("screens.dashboard.account.button-add-more-placeholder")}
            value={newOptionText}
            onChange={handleNewOptionChange}
          />
        </>
      )}
    </View>
  );
}
