import { NavigationContainer, ParamListBase } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { ActivityIndicator, View } from "react-native";
import { PostHogProvider } from "posthog-react-native";

import { useAuthContext } from "../hooks/authHook";

import LoginScreen from "../screens/auth/Login";
import AppointmentsScreen from "../screens/dashboard/Appointments";
import AccountScreen from "../screens/dashboard/Account";
import CallScreen from "../screens/call/Call";
import NotificationsScreen from "../screens/dashboard/Notifications";
import AppointmentScreen from "../screens/dashboard/Appointment";
import ScheduleScreen from "../screens/dashboard/Schedule";

import { POSTHOG_API_KEY } from "@env";

export interface NavigationParamList extends ParamListBase {
  Appointment: { id: string };
  Call: { token: string };
}

const Stack = createNativeStackNavigator<NavigationParamList>();

function RootStack() {
  const { user, isLoading } = useAuthContext();

  const linking = {
    prefixes: ["bomind://", "https://bomind.si", "http://bomind.si", "http://localhost:19006"],
    config: {
      screens: {
        Login: "login",
        Appointments: "appointments",
        Appointment: "appointment/:id",
        Notifications: "notifications",
        Schedule: "schedule",
        Account: "account",
        Call: "call/:token",
      },
    },
  };

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <>
      <NavigationContainer linking={linking}>
        <PostHogProvider apiKey={POSTHOG_API_KEY} options={{ host: "https://eu.i.posthog.com" }}>
          <Stack.Navigator
            screenOptions={{
              presentation: "fullScreenModal",
            }}
          >
            {user ? (
              <>
                <Stack.Screen
                  name="Appointments"
                  component={AppointmentsScreen}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="Appointment"
                  component={AppointmentScreen}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="Notifications"
                  component={NotificationsScreen}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="Schedule"
                  component={ScheduleScreen}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="Account"
                  component={AccountScreen}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="Call"
                  component={CallScreen}
                  initialParams={{ token: undefined }}
                  options={{
                    headerShown: false,
                  }}
                />
              </>
            ) : (
              <Stack.Screen
                name="Login"
                component={LoginScreen}
                options={{
                  headerShown: false,
                }}
              />
            )}
          </Stack.Navigator>
        </PostHogProvider>
      </NavigationContainer>
    </>
  );
}

export default RootStack;
