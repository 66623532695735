import * as React from "react";
import { t } from "i18next";
import { View, StyleSheet } from "react-native";
import { borderGrey, textLight } from "@bo/constants/colors";

import DashboardTemplate from "./template/Template";
import Text from "@bo/components/common/Text";
import Button from "@bo/components/atom/Button";
import { ScheduleCard } from "@bo/components/molecule/ScheduleCard";
import { TimePickerValue } from "@bo/components/molecule/ScheduleCard/interface";
import { useGetSchedules, usePostSchedulesRequest } from "../../services/scheduleService";
import { ScheduleUpsertRequest } from "@bo/types";
import { useIsMobile } from "../../hooks/mobileHook";
import Toast from "react-native-toast-message";
import { TBody, TD, TR, Table } from "@expo/html-elements";

const desktopStyles = StyleSheet.create({
  tableCell: {
    textAlign: "center",
    //@ts-expect-error - web only
    display: "table-cell",
  },
  addButton: {
    alignSelf: "center",
  },
  titleContainer: {
    paddingHorizontal: 64,
  },
  contentContainer: {
    paddingHorizontal: 32,
    gap: 32,
    paddingTop: 32,
  },
  dayRow: {
    //@ts-expect-error - web only
    display: "table-row",
    borderBottomColor: borderGrey,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  table: {
    //@ts-expect-error - web only
    display: "table",
    borderCollapse: "collapse",
  },
  tableBody: {
    //@ts-expect-error - web only
    display: "table-row-group",
  },
});

const mobileStyles = StyleSheet.create({
  tableCell: {
    textAlign: "center",
    //@ts-expect-error - web only
    display: "table-cell",
  },
  addButton: {
    alignSelf: "center",
  },
  titleContainer: {
    paddingHorizontal: 32,
    paddingBottom: 16,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
    gap: 16,
  },
  dayRow: {
    //@ts-expect-error - web only
    display: "table-row",
    alignItems: "center",
    borderColor: borderGrey,
    borderBottomWidth: 1,
  },
  table: {
    //@ts-expect-error - web only
    display: "table",
    borderCollapse: "collapse",
  },
  tableBody: {
    //@ts-expect-error - web only
    display: "table-row-group",
  },
});

export default function ScheduleScreen() {
  const isMobile = useIsMobile();

  const styles = isMobile ? mobileStyles : desktopStyles;

  const [timeslots, setTimeslots] = React.useState<[TimePickerValue | null, TimePickerValue | null][][]>([
    [],
    [],
    [],
    [],
    [],
  ]);

  const { refetch: getSchedules, data: schedules, error: schedulesError } = useGetSchedules();
  const { mutate: updateSchedules } = usePostSchedulesRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "posodabljanju urnika" }),
      });
    },
    () => {
      Toast.show({
        type: "success",
        text1: t("toasts.default-success-title"),
        text2: t("toasts.default-success-message", { description: "posodobil-a urnik" }),
      });
    },
  );

  const handleAddClick = (dayIndex: number) => {
    const newTimeslots = [...timeslots];
    newTimeslots[dayIndex].push([
      { hour: 0, minutes: 0 },
      { hour: 0, minutes: 0 },
    ]);
    setTimeslots(newTimeslots);
  };

  const handleDelete = (dayIndex: number, index: number) => {
    const newTimeslots = [...timeslots];
    newTimeslots[dayIndex].splice(index, 1);
    setTimeslots(newTimeslots);
  };

  const handleTimeFromChange = (dayIndex: number, index: number, value: TimePickerValue | null) => {
    const newTimeslots = [...timeslots];
    newTimeslots[dayIndex][index][0] = value;
    setTimeslots(newTimeslots);
  };

  const handleTimeToChange = (dayIndex: number, index: number, value: TimePickerValue | null) => {
    const newTimeslots = [...timeslots];
    newTimeslots[dayIndex][index][1] = value;
    setTimeslots(newTimeslots);
  };

  const handleSave = () => {
    const request: ScheduleUpsertRequest = {
      schedules: timeslots.flatMap((timeslot, dayIndex) => {
        return timeslot.map((value) => {
          return {
            dayIndex: dayIndex + 1,
            fromHour: value[0]?.hour ?? 0,
            fromMinutes: value[0]?.minutes ?? 0,
            toHour: value[1]?.hour ?? 0,
            toMinutes: value[1]?.minutes ?? 0,
          };
        });
      }),
    };

    updateSchedules(request);
  };

  React.useEffect(() => {
    getSchedules();
  }, []);

  React.useEffect(() => {
    if (!schedules) {
      return;
    }

    const newTimeslots: typeof timeslots = [[], [], [], [], []];

    schedules.data.forEach((schedule) => {
      const index = schedule.dayIndex - 1; // handle sunday being 0

      newTimeslots[index].push([
        { hour: schedule.fromHour, minutes: schedule.fromMinutes },
        { hour: schedule.toHour, minutes: schedule.toMinutes },
      ]);
    });

    setTimeslots(newTimeslots);
  }, [schedules]);

  React.useEffect(() => {
    if (schedulesError) {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "pridobivanju urnika" }),
      });
    }
  }, [schedulesError]);

  return (
    <DashboardTemplate>
      <View style={styles.titleContainer}>
        <Text type="H3">{t("screens.dashboard.schedule.title")}</Text>
      </View>
      <View style={styles.contentContainer}>
        <Table style={styles.table}>
          <TBody style={styles.tableBody}>
            {["monday", "tuesday", "wednesday", "thursday", "friday"].map((day, dayIndex) => (
              <TR key={day} style={styles.dayRow}>
                <TD style={styles.tableCell}>
                  <Text type="P1">{t(`screens.dashboard.schedule.${day}`)}</Text>
                </TD>
                <TD style={styles.tableCell}>
                  {timeslots[dayIndex].map((value, index) => (
                    <ScheduleCard
                      key={index}
                      value={value}
                      onDelete={() => handleDelete(dayIndex, index)}
                      onFromChange={(value) => handleTimeFromChange(dayIndex, index, value)}
                      onToChange={(value) => handleTimeToChange(dayIndex, index, value)}
                    />
                  ))}
                </TD>
                <TD style={styles.tableCell}>
                  <Button type="secondary" onPress={() => handleAddClick(dayIndex)} buttonStyle={styles.addButton}>
                    {t("screens.dashboard.schedule.add")}
                  </Button>
                </TD>
              </TR>
            ))}
          </TBody>
        </Table>

        <Button type="primary" onPress={handleSave}>
          {t("screens.dashboard.schedule.button")}
        </Button>
      </View>
    </DashboardTemplate>
  );
}
